import { useEffect, useMemo, useRef } from "react";
import { CSVLink } from "react-csv";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

import { GroupedHeadRow, TableBodyRow, TableHeadCell } from ".";

interface TableCSVDownloadInfoBase<CellId, GroupedHeadId> {
  filename: string;
  /**
   * 엑셀 다운로드시 제외하고 싶은 데이터의 id들
   */
  idsToExceptForExcelDownload?: CellId[];
  /**
   * 엑셀 다운로드 시 제외하고 싶은 groupedHeadRow의 id들
   */
  groupedHeadRowIdsToExceptForExcelDownload?: GroupedHeadId[];
  /**
   * 표 상단에 표시하고 싶은 데이터가 있을때 사용
   * 자유롭게 사용할 수 있도록 최대한 단순하게 정의함
   */
  metaInfo?: (string | number)[][];
  /**
   * CSV의 컬럼이 화면에 보이는 컬럼과 달라지는 경우 사용
   * headCellsForCSVDownload을 추가할 경우 headCells 보다 우선해서 적용됨
   */
  headCellsForCSVDownload?: TableHeadCell<CellId>[];
  /**
   * CSV의 컬럼이 화면에 보이는 컬럼과 달라지는 경우 사용
   * groupedHeadRowForCSVDownload을 추가할 경우 groupedHeadRow 보다 우선해서 적용됨
   */
  groupedHeadRowForCSVDownload?: GroupedHeadRow<GroupedHeadId, CellId>;
}

interface TableCSVDownloadInfoAsCurrentPage<CellId extends string> {
  scope: "currentPage";
  rowsForCSVDownload: TableBodyRow<CellId>[];
}

interface TableCSVDownloadInfoAsAll<CellId extends string> {
  scope: "all";
  isCSVDownloadRequested: boolean;
  setIsCSVDownloadRequested: (val: boolean) => void;
  rowsForCSVDownload: TableBodyRow<CellId>[];
  removeQueryOfFetchDataForCSVDownload: () => void;
  statusOfQueryOfFetchDataForCSVDownload:
    | "idle"
    | "error"
    | "loading"
    | "success";
  ResponseHandlerOfFetchDataForCSVDownload?: React.ReactNode;
}

export type TableCSVDownloadInfo<
  CellId extends string,
  GroupedHeadId
> = TableCSVDownloadInfoBase<CellId, GroupedHeadId> &
  (
    | TableCSVDownloadInfoAsCurrentPage<CellId>
    | TableCSVDownloadInfoAsAll<CellId>
  );

export default function CSVDownload<
  CellId extends string,
  GroupedHeadId = void
>({
  headCells,
  groupedHeadRow,
  csvDownloadInfo,
}: {
  headCells: TableHeadCell<CellId>[];
  groupedHeadRow?: GroupedHeadRow<GroupedHeadId, CellId>;
  csvDownloadInfo: TableCSVDownloadInfo<CellId, GroupedHeadId>;
}) {
  const downloadBtnRef = useRef<HTMLDivElement>(null);

  const csvData = useMemo(() => {
    if (!csvDownloadInfo) return [];

    // rows에 React.ReactNode같은 타입이 있으면 오동작할 수 있음에 유의

    if (!headCells) return [];

    const dictToExceptForExcelDownload = (() => {
      const dict: { [K in string]?: boolean } = {};

      csvDownloadInfo.idsToExceptForExcelDownload?.forEach((id) => {
        dict[id] = true;
      });

      return dict;
    })();

    const metaInfoRow = csvDownloadInfo.metaInfo || [];

    const processedGroupedHeadRow = (() => {
      let result: string[] = [];

      (csvDownloadInfo.groupedHeadRowForCSVDownload ?? groupedHeadRow)
        ?.filter(
          (gh) =>
            !csvDownloadInfo.groupedHeadRowIdsToExceptForExcelDownload?.includes(
              gh.id
            )
        )
        .map((gh) => {
          const subResult: string[] = [];

          gh.headCellIds.forEach((c) => {
            if (!dictToExceptForExcelDownload[c]) {
              // colspan을 표현
              subResult.push("");
            }
          });

          subResult[0] = gh.label;

          result = [...result, ...subResult];

          return result;
        });

      return result;
    })();

    const headRow = (csvDownloadInfo.headCellsForCSVDownload ?? headCells)
      .filter((hc) => !dictToExceptForExcelDownload[hc.id])
      .map((hc) => hc.labelForCSV || hc.label);

    const dataRows = (() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any[] = [];

      const targetRows = csvDownloadInfo.rowsForCSVDownload;

      targetRows.forEach((row) => {
        // 핸들러 함수 등 칼럼 데이터와 관계 없는 정보는 제외한다
        const { handleRowClick, backgroundColor, ...pureRow } = row;

        // idsToExceptForExcelDownload로 다운로드시 제외를 원한필드는 제외한다
        const validRow = Object.fromEntries(
          Object.entries(pureRow).filter(
            ([key]) => !dictToExceptForExcelDownload[key]
          )
        );

        const arrFromRow = Object.values(validRow);

        const processedArrFromRow = arrFromRow.map((v) => {
          if (typeof v === "string") {
            if (v.includes('"')) {
              return v.replace(/"/g, '""');
            }

            // CSV에서 string을 강제로 number로 바꾸는 오류가 있어서 이 작업을 해줌 (ex. 알파벳 E를 exponent로 인식 등)
            return '=""' + v + '""';
          }

          return v;
        });

        result.push(processedArrFromRow);
      });

      return result;
    })();

    return [
      ...metaInfoRow,
      ...(processedGroupedHeadRow.length ? [processedGroupedHeadRow] : []),
      headRow,
      ...dataRows,
    ];
  }, [headCells, groupedHeadRow, csvDownloadInfo]);

  // all 다운로드인 경우, 다운로드가 요청되었고 data가 준비되었다면 수동으로 다운로드 버튼 클릭 이벤트를 발생시킨다
  useEffect(() => {
    if (csvDownloadInfo.scope !== "all") {
      return;
    }

    if (
      !(
        csvDownloadInfo.isCSVDownloadRequested &&
        csvDownloadInfo.statusOfQueryOfFetchDataForCSVDownload === "success"
      )
    ) {
      return;
    }

    downloadBtnRef.current?.click();

    // 다운로드 요청후 관련 state를 초기화
    csvDownloadInfo.setIsCSVDownloadRequested(false);
  }, [csvDownloadInfo.scope, csvDownloadInfo, csvData]);

  // all 다운로드인 경우, 이전 다운로드관련 데이터가 있는경우 리셋한다.
  useEffect(() => {
    if (
      csvDownloadInfo.scope === "all" &&
      (csvDownloadInfo.statusOfQueryOfFetchDataForCSVDownload === "success" ||
        csvDownloadInfo.statusOfQueryOfFetchDataForCSVDownload === "error") &&
      !csvDownloadInfo.isCSVDownloadRequested
    ) {
      csvDownloadInfo.removeQueryOfFetchDataForCSVDownload();
    }
  }, [csvDownloadInfo]);

  if (csvDownloadInfo.scope === "all") {
    /**
     * all 데이터에 대해 CSV 다운로드를 할 때의 flow
     * 1) 다운로드 버튼을 클릭
     * 2) isCSVDownloadRequested를 true로 변경
     * 3) (Table을 사용하는 컴포넌트에서) 다운로드용 데이터를 fetch 다운로드용 데이터를 요청
     * 4) isCSVDownloadRequested이고 다운로드용 데이터가 도착했다면 다운로드를 요청 (CSV 다운로드용 버튼에 클릭이벤트를 발생시킴)
     */
    return (
      <>
        <Button
          variant="outlined"
          size="medium"
          startIcon={<FileDownloadOutlined />}
          onClick={() => csvDownloadInfo.setIsCSVDownloadRequested(true)}
        >
          CSV 다운로드
        </Button>

        <CSVLink data={csvData} filename={csvDownloadInfo.filename}>
          <div ref={downloadBtnRef} />
        </CSVLink>

        {csvDownloadInfo.ResponseHandlerOfFetchDataForCSVDownload}
      </>
    );
  } else {
    return (
      <CSVLink data={csvData} filename={csvDownloadInfo.filename}>
        <Button
          variant="outlined"
          size="medium"
          startIcon={<FileDownloadOutlined />}
        >
          CSV 다운로드
        </Button>
      </CSVLink>
    );
  }
}
