import { ChangeEvent } from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

function CommentDataList({
  comment,
  onCommentInputChange,
  onCommentUpdate,
  commentData,
  UploadResponseSnackBar,
}: {
  comment: string;
  onCommentInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCommentUpdate: () => void;
  commentData: Comment[] | null;
  UploadResponseSnackBar: JSX.Element;
}) {
  return (
    <Grid container sx={{ marginTop: "16px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          댓글
        </Typography>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={8}>
          <TextField
            value={comment}
            label="댓글 입력"
            fullWidth
            size="small"
            multiline={true}
            onChange={onCommentInputChange}
          />
        </Grid>

        <Grid item xs={2}>
          <Button variant="contained" onClick={onCommentUpdate}>
            저장
          </Button>
        </Grid>
      </Grid>

      {commentData && (
        <Grid item xs={12}>
          <List>
            {commentData.map((n, index) => {
              return (
                <ListItem key={index} disablePadding>
                  <ListItemIcon>{n.id}</ListItemIcon>

                  <ListItemText
                    sx={{
                      marginLeft: "16px",
                      color: n.type === "admin" ? "#f44336" : "inherit",
                    }}
                    primary={n.comment}
                    secondary={
                      // Date인지 문자열인지 확인을 한다 ISO string으로 요청/응답을 정하기 전 사용하고 있던 toLocaleString와 구별
                      isNaN(Date.parse(n.date))
                        ? n.date
                        : toFormattedDate(
                            n.date,
                            "YYYY.MM.DD a hh:mm:ss",
                            false
                          )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}

      {UploadResponseSnackBar}
    </Grid>
  );
}

export default CommentDataList;
