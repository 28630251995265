import styled, { css } from "styled-components";

import {
  disableSettingOfShowVerticalScrollBarAlways,
  mobile,
} from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const containerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99997;

  /* *-admin 프로젝트에서 showVerticalScrollBarAlways를 적용한 것때문에 UI가 다르게 표시되는 부분이 있어 모달 내부에서만 리셋하는 코드를 추가  */
  * {
    ${disableSettingOfShowVerticalScrollBarAlways()}
  }
`;

const container = styled.div<{
  allowsOverflow?: boolean;
  uiType:
    | "titleOnly"
    | "webNegativeTitleOnly"
    | "webNegative"
    | "content"
    | "contentWithCustomBody"
    | "formInput"
    | "formInputWithTab";
  hasBorder: boolean;
  disableMaxHeight?: boolean;
  isWebNegativeTitle?: boolean;
  isWebNegativeActionPositive?: boolean;
  isActiveTabWithButton?: boolean;
}>`
  display: inline-block;
  position: relative;
  background-color: ${COLOR.white};
  text-align: center;
  overflow: ${(props) => (props.allowsOverflow ? "visible" : "scroll")};
  cursor: default;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  min-width: 28.75rem;
  max-height: calc(100% - 16px);
  max-width: calc(100% - 16px);
  z-index: 99999;

  ${mobile(css`
    min-width: 90%;
  `)}

  > .actions {
    height: 4.125rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.5rem;

    > .negative {
      cursor: pointer;
      ${setFontStyle("Body5")};
      color: ${COLOR.grayScale_600};
      margin-right: 2rem;

      ${mobile(css`
        ${setFontStyle("Body6")};
      `)}
    }

    > .positive {
      cursor: pointer;
      ${setFontStyle("SubHead1", "Bold")};
      color: ${COLOR.primaryBlue};

      ${mobile(css`
        ${setFontStyle("SubHead2", "Bold")};
      `)}
    }
  }

  > .barcode-actions {
    display: flex;
    width: 544px;
    justify-content: flex-end;

    > .barcode-action-container {
      width: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        height: 56px;
      }
    }

    > .barcode-action-container:last-child {
      margin-left: 64px;
    }
  }

  ${(props) => {
    switch (props.uiType) {
      case "content": {
        return css`
          padding: 2.5rem 1.5rem 0;

          > .header {
            > .title {
              ${setFontStyle("SubHead1", "Bold")};
              color: ${COLOR.grayScale_700};
            }
          }

          > .body {
            margin-top: 1.5rem;
            ${setFontStyle("Body6")};
            color: ${COLOR.grayScale_700};
          }

          > .barcode-actions {
            margin: 40px 56px 38px;
          }
        `;
      }

      case "titleOnly":
      case "webNegativeTitleOnly": {
        return css`
          ${mobile(css`
            min-width: 18rem;
          `)}

          > .header {
            padding: 2.5rem;
            text-align: left;
            min-height: 8.375rem;

            ${mobile(css`
              padding: 1.5rem 1rem;
              min-height: 7rem;
            `)}

            > .title {
              ${setFontStyle("SubHead2", "Bold")};
              color: ${props.uiType === "webNegativeTitleOnly" ||
              (props.uiType === "titleOnly" && props.isWebNegativeTitle)
                ? COLOR.pointWarning
                : COLOR.grayScale_700};
            }
          }

          .actions .positive {
            color: ${props.uiType === "webNegativeTitleOnly" ||
            (props.uiType === "titleOnly" && props.isWebNegativeActionPositive)
              ? COLOR.pointWarning
              : COLOR.primaryBlue};
          }

          > .barcode-actions {
            margin: 0 24px 24px;
          }
        `;
      }

      case "contentWithCustomBody": {
        return css`
          padding: 1rem 1.5rem;

          ${mobile(css`
            padding: 0.75rem 1rem;
          `)}

          > .header {
            position: relative;

            > .title {
              ${setFontStyle("SubHead2", "Bold")};
              color: ${COLOR.grayScale_800};
            }

            > .icon {
              position: absolute;
              top: -0.25rem;
              right: -0.875rem;
              cursor: pointer;
            }
          }

          > .body {
            margin-top: 2rem;
            overflow: ${props.allowsOverflow ? "visible" : "scroll"};

            ${mobile(css`
              margin-top: 1.75rem;
              text-align: center;
            `)}
          }
        `;
      }

      case "webNegative": {
        return css`
          padding: 40px 24px 10px 24px;

          ${mobile(css`
            padding: 12px 16px;
          `)}

          > .header {
            > .title {
              ${setFontStyle("SubHead1", "Bold")};
              color: ${COLOR.pointWarning};
            }
          }

          > .body {
            margin-top: 24px;
            min-height: 112px;
            ${setFontStyle("Body6")};
            color: ${COLOR.grayScale_700};
          }

          > .actions {
            height: 56px;
            padding: 0;

            > .negative {
              ${setFontStyle("SubHead2")};
              color: ${COLOR.grayScale_600};
              margin-right: 32px;
            }

            > .positive {
              ${setFontStyle("SubHead2", "Bold")};
              color: ${COLOR.pointWarning};
            }
          }
        `;
      }

      case "formInput": {
        return css`
          overflow: scroll;
          width: 90%;
          max-width: 1232px;
          ${!props.disableMaxHeight &&
          css`
            max-height: 720px;
          `};
          padding: 40px;
          text-align: left;

          ${mobile(css`
            padding: 24px 8px 40px 8px;
          `)}

          > .header {
            position: relative;

            ${mobile(css`
              position: static;
            `)}

            > .title {
              ${setFontStyle("Head5", "Bold")};
              color: ${COLOR.primaryBlue};

              ${mobile(css`
                margin-left: 8px;
              `)}
            }

            > .desc {
              ${setFontStyle("Body6")};
              color: ${COLOR.grayScale_700};
              margin-top: 8px;
            }

            > .icon {
              position: absolute;
              top: -0.25rem;
              right: -0.875rem;
              cursor: pointer;

              ${mobile(css`
                top: 24px;
                right: 16px;
              `)}
            }
          }

          > .body {
            margin-top: 2rem;
            overflow: auto;
            border: ${props.hasBorder
              ? `1px solid ${COLOR.grayScale_400}`
              : `0px`};
            padding: ${props.hasBorder ? "16px" : "0px"};
            border-radius: 4px;

            ${mobile(css`
              margin-top: 8px;
              overflow-x: hidden;
            `)}
          }

          > .submit-button-container {
            display: flex;
            justify-content: center;
            padding: 40px;
          }
        `;
      }

      case "formInputWithTab": {
        return css<{ isActiveTabWithButton?: boolean }>`
          overflow: scroll;
          width: 90%;
          max-width: 1232px;
          ${!props.disableMaxHeight &&
          css`
            max-height: 720px;
          `};
          padding: 40px 40px 80px;
          text-align: left;

          > .header {
            position: relative;

            > .title {
              ${setFontStyle("Head5", "Bold")};
              color: ${COLOR.primaryBlue};
            }

            > .desc {
              ${setFontStyle("Body6")};
              color: ${COLOR.grayScale_700};
              margin-top: 8px;
            }

            > .icon {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
            }
          }

          .form-input-with-tab-body-container {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            .form-input-with-tab-body-header {
              padding-left: 8px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;

              .button-list {
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                .button:not(:last-child) {
                  margin-right: 24px;
                }
              }
            }

            > .body {
              position: relative;
              padding: 16px;
              ${({ isActiveTabWithButton }) =>
                isActiveTabWithButton &&
                css`
                  margin-bottom: 80px;
                `};
              border: 1px solid ${COLOR.grayScale_400};
              border-radius: 4px;

              .tab-submit-button-container {
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                gap: 16px;
              }
            }
          }
        `;
      }
    }
  }}
`;

const dimmedBackground = styled.div`
  background-color: ${COLOR.bk_40};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
`;

export default {
  containerWrapper,
  container,
  dimmedBackground,
};
