import { atom } from "recoil";

import { WithdrawalRequestDetail } from "../../../types/forwarding/trello";

const TRELLO_PURCHASE_WITHDRAWAL_LIST_FORM = atom<WithdrawalRequestDetail[]>({
  key: "forwarding/adminTrello/atoms/TRELLO_PURCHASE_WITHDRAWAL_LIST_FORM",
  default: [] as WithdrawalRequestDetail[],
});

const TRELLO_PURCHASE_WITHDRAWAL_ITEM_FORM = atom<WithdrawalRequestDetail>({
  key: "forwarding/adminTrello/atoms/TRELLO_PURCHASE_WITHDRAWAL_ITEM_FORM",
  default: {} as WithdrawalRequestDetail,
});

const IS_LOADED_WITHDRAWAL_TEMPLATE_DATA = atom<boolean>({
  key: "forwarding/adminTrello/atoms/IS_LOADED_WITHDRAWAL_TEMPLATE_DATA",
  default: false,
});

const TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT = atom<number>({
  key: "forwarding/adminTrello/atoms/TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT",
  default: 0,
});

export default {
  TRELLO_PURCHASE_WITHDRAWAL_LIST_FORM,
  TRELLO_PURCHASE_WITHDRAWAL_ITEM_FORM,
  IS_LOADED_WITHDRAWAL_TEMPLATE_DATA,
  TRELLO_SALES_MANAGEMENT_DEPOSIT_AMOUNT,
};
