import styled from "styled-components";

const search = styled.div`
  position: relative;

  > .search-type {
    margin-right: 4px;
  }

  > .clear {
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: 6px;
  }
`;

const searchPanelWrapper = styled.div``;

const searchPanel = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;

  > div {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export default {
  search,
  searchPanel,
  searchPanelWrapper,
};
