import { useCallback, useMemo, useRef, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";

import useTableHeadFilterUnstyled from "../../../../../../hooks/useTableHeadFilterUnstyled";

export default function useForwardingManagerIdFilter(
  bidFilterData: GET_ADMIN_BID_LIST_FILTER
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isCanceled, setIsCanceled] = useState<boolean>(true);

  const forwardingManagerIdHistory = useMemo(() => {
    if (bidFilterData.forwardingManagerIdList) {
      return { forwardingManagerIdList: bidFilterData.forwardingManagerIdList };
    }
  }, [bidFilterData]);

  const [headFilterData, setHeadFilterData] = useState<{
    forwardingManagerIdList: number[];
  } | null>(forwardingManagerIdHistory ?? null);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  const anchorElRef = useRef<HTMLDivElement>(null);

  const {
    FilterPanel: ForwardingManagerFilterPanel,
    draftFilter: forwardingManagerFilterData,
    handleFilterReset: ForwardingManagerReset,
  } = useTableHeadFilterUnstyled({
    filterOptions: ADMIN_FORWARDING_MANAGER_OPTION_LIST,
  });

  const userIdFilter = useMemo(() => {
    return {
      forwardingManagerIdList: forwardingManagerFilterData,
    };
  }, [forwardingManagerFilterData]);

  const handleSomewhereClick = useCallback(() => {
    setAnchorEl(null);
    setIsCanceled(true);
  }, []);

  const handleSubmitFilter = useCallback(() => {
    if (!userIdFilter) return setAnchorEl(null);

    if (userIdFilter) {
      setHeadFilterData(userIdFilter);
      setIsCanceled(false);
      setAnchorEl(null);
    }
  }, [userIdFilter]);

  const handleFilterOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (isCanceled) {
        if (forwardingManagerIdHistory) {
          ForwardingManagerReset(
            forwardingManagerIdHistory.forwardingManagerIdList
          );
        }

        return setIsCanceled(false);
      } else {
        userIdFilter &&
          ForwardingManagerReset(userIdFilter?.forwardingManagerIdList);
        return setIsCanceled(false);
      }
    },

    [
      anchorEl,
      forwardingManagerIdHistory,
      ForwardingManagerReset,
      isCanceled,
      userIdFilter,
    ]
  );

  const filterTriggerPosition = (() => {
    const target = anchorElRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const handleReset = useCallback(() => {
    ForwardingManagerReset([]);
    setHeadFilterData(null);
  }, [ForwardingManagerReset]);

  const handleInsideReset = useCallback(() => {
    ForwardingManagerReset([]);
  }, [ForwardingManagerReset]);

  const ForwardingManagerFilter = useMemo(() => {
    const SingleFilter = () => {
      return (
        <>
          <IconButton
            aria-owns={id}
            aria-describedby={id}
            aria-haspopup={true}
            type="button"
            onClick={handleFilterOpen}
            color={
              !isEmptyObjectOrArray(
                headFilterData?.forwardingManagerIdList || {}
              )
                ? "primary"
                : "inherit"
            }
          >
            <FilterAltIcon />
          </IconButton>

          <Dialog open={open}>
            <Popper
              key={id}
              placement="bottom-start"
              anchorEl={anchorEl}
              id={id}
              open={open}
              transition
              style={{ zIndex: 1300 }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={handleSomewhereClick}
                  disableReactTree
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        padding: 2,
                        width: "100%",
                        minWidth: "160px",
                        position: "absolute",
                        maxHeight: "300px",
                        overflow: "scroll",
                        top: (filterTriggerPosition?.top ?? 0) + 17,
                        left: (filterTriggerPosition?.left ?? 0) - 40,
                        right: "100%",
                        display: "flex",
                        zIndex: 1300,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: 160,
                          height: 50,
                          position: "fixed",
                          top: 300,
                          left: -40,
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <IconButton onClick={() => handleInsideReset()}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-flex" }}
                          >
                            <RestartAltIcon />
                            reset
                          </Typography>
                        </IconButton>
                        <Button variant="outlined" onClick={handleSubmitFilter}>
                          적용
                        </Button>
                      </Box>
                      <Grid>{ForwardingManagerFilterPanel}</Grid>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Dialog>
        </>
      );
    };

    return <Box>{SingleFilter()}</Box>;
  }, [
    id,
    open,
    filterTriggerPosition?.left,
    filterTriggerPosition?.top,
    handleFilterOpen,
    anchorEl,
    handleSomewhereClick,
    ForwardingManagerFilterPanel,
    headFilterData,
    handleInsideReset,
    handleSubmitFilter,
  ]);

  return {
    ForwardingManagerFilter,
    headFilterData,
    handleReset,
  };
}
