import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";
import { useRecoilValue } from "recoil";

import { FilterDataKey } from "@sellernote/_shared/src/types/forwarding/common";

import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "../../../../../states/filters";
import useForwardingManagerIdFilter from "./useForwardingManagerIdFilter";
import useFreightAndIncotermsHeadFilter from "./useFreightAndIncotermsHeadFilter";
import useInProgressFilter from "./useInProgressFilter";
import useInProgressFilterNotYet from "./useInProgressFilterNotYet";

export default function useTableHeadPanel(filterDataKey: FilterDataKey) {
  const { pathname } = useLocation();

  const filterData = useRecoilValue(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS[filterDataKey]
  );

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData);

  const {
    FreightAndIncotermsHeadFilter,
    headFilterData: FreightAndIncotermsFilterData,
    handleReset: FreightAndIncotermsReset,
  } = useFreightAndIncotermsHeadFilter(filterData);

  const {
    InProgressFilter,
    headFilterData: InProgressFilterData,
    handleReset: InProgressReset,
  } = useInProgressFilter(filterData);

  const {
    NotYetFilter,
    headFilterData: NotYetFilterData,
    handleReset: NotYetFilterReset,
  } = useInProgressFilterNotYet({ key: "removed" });

  const tableHeadFilterData = useMemo(() => {
    if (pathname.startsWith("/sub")) {
      return {
        ...ForwardingManagerIdFilterData,
        ...FreightAndIncotermsFilterData,
        ...NotYetFilterData,
      };
    }
    return {
      ...ForwardingManagerIdFilterData,
      ...FreightAndIncotermsFilterData,
      ...InProgressFilterData,
    };
  }, [
    FreightAndIncotermsFilterData,
    ForwardingManagerIdFilterData,
    InProgressFilterData,
    NotYetFilterData,
    pathname,
  ]);

  const AllResetButton = useMemo(() => {
    return (
      <Tooltip title="테이블 헤드 필터 전체 리셋">
        <IconButton
          onClick={() => {
            FreightAndIncotermsReset();
            ForwardingManagerIdReset();
            InProgressReset();
            NotYetFilterReset();
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    );
  }, [
    ForwardingManagerIdReset,
    FreightAndIncotermsReset,
    InProgressReset,
    NotYetFilterReset,
  ]);

  const TableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    FreightAndIncotermsHeadFilter: FreightAndIncotermsHeadFilter,
    InProgressFilter: pathname.startsWith("/sub")
      ? NotYetFilter
      : InProgressFilter,
    AllResetButton: AllResetButton,
  };
  const NotYetTableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    FreightAndIncotermsHeadFilter: FreightAndIncotermsHeadFilter,
    InProgressFilter: pathname.startsWith("/sub")
      ? NotYetFilter
      : InProgressFilter,
    AllResetButton: AllResetButton,
  };
  return {
    tableHeadFilterData,
    TableHeadPanel,
    NotYetTableHeadPanel,
    AllResetButton,
  };
}
