import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { useRecoilState } from "recoil";

import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import CUSTOMS_ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_BID_QUERY";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useAdminBidList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useAdminBidList";
import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/bid";
import useBidTablePanel from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/:locale/BidNewTable/useBidTablePanel";
import useTableHeadPanel from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/:locale/BidNewTable/useTableHeadPanel";
import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/states/filters";

const BidKR = () => {
  const {
    locale,
  }: {
    locale: string;
  } = useParams();

  const [filterData, setFilterData] = useRecoilState(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS.ADMIN_BID_FILTER_LIST
  );

  const [{ page, perPage }, setPageFilter] = useAtom(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.BID_TABLE_PAGINATION
  );

  const {
    TablePanel,
    debouncedSearchTermWithObject,
    dateFilter,
    objectServiceTypeKey,
    objectStatusKey,
    objectProjectStatusKey,
  } = useBidTablePanel("ADMIN_BID_FILTER_LIST");

  const { TableHeadPanel, tableHeadFilterData } = useTableHeadPanel(
    "ADMIN_BID_FILTER_LIST"
  );

  const saveFilterDataRef = useCallback(() => {
    setFilterData({
      ...debouncedSearchTermWithObject,
      ...dateFilter,
      ...objectServiceTypeKey,
      ...objectStatusKey,
      ...objectProjectStatusKey,
      ...tableHeadFilterData,
    });
  }, [
    tableHeadFilterData,
    setFilterData,
    debouncedSearchTermWithObject,
    dateFilter,
    objectServiceTypeKey,
    objectStatusKey,
    objectProjectStatusKey,
  ]);

  const { data: bidListData } = CUSTOMS_ADMIN_BID_QUERY.useGetCustomsBidList({
    order: "DESC",
    page: page,
    perPage: perPage,
    locale,
    ...filterData,
  });

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: bidListData?.total || 0,
    perPage: perPage,
    setPerPage: (perPage: number) => setPageFilter({ page, perPage }),
    currentPage: page,
    setCurrentPage: (page: number) => setPageFilter({ perPage, page }),
  };

  const { adminUserList, adminDataForTableFilter } =
    ADMIN_USER_QUERY.useGetAdminUserList();

  const { BidList } = useAdminBidList({
    bidListData,
    adminUserList,
    adminDataForTableFilter,
    TablePanel,
    TableHeadPanel,
    pagination,
    refCallback: saveFilterDataRef,
  });

  return BidList;
};

export default withRequireAuth(BidKR);
