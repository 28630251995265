import {
  InvoiceResult,
  TrelloBidManagement,
} from "../../types/forwarding/trello";

function changeInvoiceResultToKr(invoiceResult: InvoiceResult | undefined) {
  if (invoiceResult === "complete") {
    return "일치";
  }

  if (invoiceResult === "over") {
    return "초과";
  }

  if (invoiceResult === "unpaid") {
    return "미수";
  }
  return "";
}

function getSettlementBLNumber(
  management: Pick<TrelloBidManagement, "BLType" | "hBL" | "mBL">
) {
  if (!management) return "-";

  const { BLType, hBL, mBL } = management;

  if (!BLType) return "-";

  if (BLType === "MBL" || BLType === "DirectMBL") return `[MBL: ${mBL}]`;

  return `[HBL: ${hBL}]`;
}

export { changeInvoiceResultToKr, getSettlementBLNumber };
