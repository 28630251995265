/**
 * 로그인시 마지막에 사용한 팀계정으로 자동전환하는데 사용됨. (개인계정으로 전환하면 초기화됨)
 */
const LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID = "latestUsedTeamId";

/**
 * 전환한 팀 계정을 localStorage에 저장
 * - 개인 계정으로 다시 전환시, remove되어야함
 */
function saveLatestUsedTeamId(teamId: number) {
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID,
    String(teamId)
  );
}

/**
 * localStorage에 저장한 마지막 사용 팀계정 아이디를 리셋
 */
function removeLatestUsedTeamId() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID);
}

function getLatestUsedTeamId() {
  window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID);
}

export {
  saveLatestUsedTeamId,
  getLatestUsedTeamId,
  removeLatestUsedTeamId,
  LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID,
};
