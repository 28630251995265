import styled from "styled-components";

const exporterInfoContainer = styled.div`
  .title {
    font-weight: bold;
    font-size: 20px;
  }

  .ok-button {
    font-weight: bold;
    margin-right: 8px;
  }

  .cancel-button {
    font-weight: bold;
  }

  .push-mail-button {
    font-weight: bold;
    background-color: #1890ff;
    margin-right: 8px;
  }

  .edit-button {
    font-weight: bold;
    background-color: #1890ff;
  }

  .input {
    margin-top: -5px;
  }
`;

export default {
  exporterInfoContainer,
};
