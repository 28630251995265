import { useCallback, useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const useMuiSelect = ({
  options,
  title,
  minWidth,
  defaultValue,
  disabled,
  fullWidth,
}: {
  options: { value: string | number | undefined; label: string }[];
  title?: string;
  minWidth?: number;
  defaultValue?: string | number;
  disabled?: boolean;
  fullWidth?: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(defaultValue ? defaultValue : "");

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<string | number>) => {
      (event.target.value as string) &&
        setSelectedValue(event.target.value as string);
    },
    []
  );

  const MuiSelect = useMemo(() => {
    return (
      <FormControl
        size="small"
        fullWidth={fullWidth}
        sx={{ minWidth: minWidth ? `${minWidth}px` : "120px" }}
      >
        {title && <InputLabel>{title}</InputLabel>}

        <Select
          value={selectedValue || ""}
          size="small"
          label={title}
          onChange={(event: SelectChangeEvent<string | number>) =>
            handleSelectChange(event)
          }
          disabled={disabled}
        >
          {options.map((v) => {
            return (
              <MenuItem key={v.value} value={v.value || ""}>
                {v.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }, [
    fullWidth,
    minWidth,
    title,
    selectedValue,
    disabled,
    options,
    handleSelectChange,
  ]);

  return { MuiSelect, selectedValue, setSelectedValue };
};

export default useMuiSelect;
