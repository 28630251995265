import { useMemo } from "react";
import { Box } from "@mui/material";
import { BackTop } from "antd";

import { GET_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { GET_CUSTOMS_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/customsAdminBid";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { BidTableHeadPanelType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";

import Layout from "../containers/Layout";

import BidNewTable from "../pageContainers/bid/:locale/BidNewTable";

//TODO: 관세사 어드민의 response 값을 확인해서 수정해줘야 함
export default function useAdminBidList({
  bidListData,
  adminUserList,
  adminDataForTableFilter,
  TablePanel,
  TableHeadPanel,
  pagination,
  refCallback,
}: {
  bidListData:
    | GET_ADMIN_BID_LIST_RES
    | GET_CUSTOMS_ADMIN_BID_LIST_RES
    | undefined;
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  adminDataForTableFilter:
    | {
        text: string;
        value: number;
      }[]
    | undefined;
  TablePanel: JSX.Element;
  TableHeadPanel: { [key in BidTableHeadPanelType]: JSX.Element };
  pagination: {
    rowsPerPageOptions: number[];
    totalCount: number;
    perPage: number;
    setPerPage: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
  };
  refCallback?: () => void;
}) {
  const BidList = useMemo(() => {
    if (!bidListData || !adminUserList || !adminDataForTableFilter) {
      return (
        <Layout breadcrumbs={["운송 의뢰"]} title={"선적계획 의뢰"}>
          <Loading active={true} />
        </Layout>
      );
    }

    return (
      <Layout breadcrumbs={["운송 의뢰"]} title={"선적계획 의뢰"}>
        <BackTop />

        <Box ref={refCallback}>
          <BidNewTable
            adminFilterArray={adminDataForTableFilter}
            adminData={adminUserList}
            bidList={bidListData.list}
            TablePanel={TablePanel}
            TableHeadPanel={TableHeadPanel}
            pagination={pagination}
          />
        </Box>
      </Layout>
    );
  }, [
    adminDataForTableFilter,
    adminUserList,
    bidListData,
    TablePanel,
    TableHeadPanel,
    pagination,
    refCallback,
  ]);

  return { BidList };
}
