import {
  styled,
  TextField as MUITextField,
  TextFieldProps,
} from "@mui/material";
import { blue } from "@mui/material/colors";

type HighlightTextFieldProps = {
  showshighlightstext?: boolean;
} & TextFieldProps;

const TextField = styled(MUITextField)<HighlightTextFieldProps>(
  ({ theme, showshighlightstext }) => ({
    "& .MuiInputBase-input": {
      color: showshighlightstext ? blue[500] : "inherit",
    },
  })
);

// TODO: 우선은 하이라이트 기능만 추가 shipda-for-admin에서 있는 TextField를 대체할 예정
function HighlightTextField(props: HighlightTextFieldProps) {
  return <TextField {...props} />;
}

export default HighlightTextField;
