import { useCallback, useEffect, useState } from "react";
import { Control, UseFieldArrayRemove, UseFormSetValue } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminTrello";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalRequestDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { ITEM_UNIT_MEASUREMENT_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import { getFeeDataAmountByItemUnitMeasurement } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

function FormList({
  control,
  formListIndex,
  formData,
  remove,
  setValue,
  getNewFormDataItem,
  withdrawalItemList,
  exchangeRateList,
  companyType,
  isAllEditMode,
  trelloDetailData,
}: {
  control: Control<ApplyBidFormData, Record<string, unknown>>;
  formListIndex: number;
  formData: WithdrawalRequestDetail;
  remove: UseFieldArrayRemove;
  setValue: UseFormSetValue<ApplyBidFormData>;
  getNewFormDataItem: (
    formData: WithdrawalRequestDetail
  ) => WithdrawalRequestDetail;
  withdrawalItemList: string[];
  exchangeRateList: ExchangeRate[];
  companyType: PartnerBusinessArea;
  isAllEditMode: boolean;
  trelloDetailData: TrelloBidDetail;
}) {
  const withdrawalListFormState = useRecoilValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.TRELLO_PURCHASE_WITHDRAWAL_LIST_FORM
  );

  const [withdrawalItemFormState, setWithdrawalItemFormState] = useRecoilState(
    FORWARDING_ADMIN_TRELLO_ATOMS.TRELLO_PURCHASE_WITHDRAWAL_ITEM_FORM
  );

  const [isEditMode, setIsEditMode] = useState(false);

  // TODO: useEffect 제거
  // 전체 수정 값이 변할 때 개별 항목별 수정모드를 변경한다.
  useEffect(() => {
    if (isAllEditMode) {
      setIsEditMode(true);
    }

    if (!isAllEditMode) {
      setIsEditMode(false);
    }
  }, [isAllEditMode]);

  const handleRemoveForm = () => {
    remove(formListIndex);
  };

  const handleFormChange = useCallback(() => {
    setValue(`withdrawalData.${formListIndex}`, getNewFormDataItem(formData));
  }, [formData, formListIndex, getNewFormDataItem, setValue]);

  const handleItemUnitMeasurementChange = useCallback(() => {
    const newAmount = getFeeDataAmountByItemUnitMeasurement({
      amount: trelloDetailData.supply,
      itemUnitMeasurement: formData.itemUnitMeasurement,
      containersInfo: trelloDetailData.containersInfo,
      serviceType: trelloDetailData.serviceType,
      wareHouseCbm: trelloDetailData?.wareHouse?.cbm,
      totalWeight: trelloDetailData.totalWeight,
      totalCBM: trelloDetailData.totalCBM,
      unipassTotalCBM: trelloDetailData.management.invoiceCbm,
    });
    const newFormData = getNewFormDataItem({ ...formData, amount: newAmount });

    setValue(
      `withdrawalData.${formListIndex}`,
      getNewFormDataItem(newFormData)
    );
  }, [
    formData,
    formListIndex,
    getNewFormDataItem,
    setValue,
    trelloDetailData.containersInfo,
    trelloDetailData.management.invoiceCbm,
    trelloDetailData.serviceType,
    trelloDetailData.supply,
    trelloDetailData.totalCBM,
    trelloDetailData.totalWeight,
    trelloDetailData?.wareHouse?.cbm,
  ]);

  const handleEditModeOpen = useCallback(() => {
    const newFormData = { ...formData };

    setWithdrawalItemFormState(newFormData);
    setIsEditMode(true);
  }, [formData, setWithdrawalItemFormState]);

  const handleEditModeCancel = useCallback(() => {
    // 전체 수정이 활성화일 때 개별 취소시 전역으로 저장된 전체 데이터에서 가져온다.
    if (isAllEditMode) {
      setValue(
        `withdrawalData.${formListIndex}`,
        withdrawalListFormState[formListIndex]
      );
      setIsEditMode(false);
      return;
    }
    setValue(`withdrawalData.${formListIndex}`, withdrawalItemFormState);
    setIsEditMode(false);
  }, [
    isAllEditMode,
    setValue,
    formListIndex,
    withdrawalItemFormState,
    withdrawalListFormState,
  ]);

  const getTypographyOfFormValue = useCallback(
    (formDataKey: keyof WithdrawalRequestDetail) => {
      if (
        formDataKey === "amount" ||
        formDataKey === "unitPrice" ||
        formDataKey === "finalPrice" ||
        formDataKey === "totalPrice" ||
        formDataKey === "vatPrice"
      ) {
        return (
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(formData[formDataKey])}
          </Typography>
        );
      }

      return (
        <Typography variant="body1" component="div">
          {formData[formDataKey]}
        </Typography>
      );
    },
    [formData]
  );

  return (
    <Grid
      item
      container
      columns={16}
      spacing={2}
      sx={{ marginTop: "8px" }}
      key={formListIndex}
    >
      <Grid item xs={2}>
        {isEditMode ? (
          <AutoCompleteWithReactHookForm
            name={`withdrawalData.${formListIndex}.name`}
            control={control}
            defaultValue={formData.name}
            options={withdrawalItemList}
          />
        ) : (
          getTypographyOfFormValue("name")
        )}
      </Grid>

      <Grid item xs={1.5}>
        {isEditMode ? (
          <AutoCompleteWithReactHookForm
            name={`withdrawalData.${formListIndex}.currency`}
            control={control}
            options={exchangeRateList.map((v) => {
              return v.currency;
            })}
            handleEffectOnChange={handleFormChange}
          />
        ) : (
          getTypographyOfFormValue("currency")
        )}
      </Grid>

      <Grid item xs={1.5}>
        {isEditMode ? (
          <TextFieldWithReactHookForm
            name={`withdrawalData.${formListIndex}.unitPrice`}
            control={control}
            inputProps={{ style: { textAlign: "right" } }}
            defaultValue={formData.unitPrice}
            type="number"
            handleEffectOnChange={handleFormChange}
          />
        ) : (
          getTypographyOfFormValue("unitPrice")
        )}
      </Grid>

      <Grid item xs={2}>
        {isEditMode ? (
          <AutoCompleteWithReactHookForm
            name={`withdrawalData.${formListIndex}.itemUnitMeasurement`}
            control={control}
            options={ITEM_UNIT_MEASUREMENT_OPTION_LIST.map((v) => {
              return v.value;
            })}
            handleEffectOnChange={handleItemUnitMeasurementChange}
          />
        ) : (
          getTypographyOfFormValue("itemUnitMeasurement")
        )}
      </Grid>

      <Grid item xs={1}>
        {isEditMode ? (
          <TextFieldWithReactHookForm
            name={`withdrawalData.${formListIndex}.amount`}
            control={control}
            inputProps={{ style: { textAlign: "right" } }}
            defaultValue={formData.amount}
            type="number"
            handleEffectOnChange={handleFormChange}
          />
        ) : (
          getTypographyOfFormValue("amount")
        )}
      </Grid>

      {companyType !== "foreign" && (
        <Grid item xs={2}>
          {isEditMode ? (
            <TextFieldWithReactHookForm
              name={`withdrawalData.${formListIndex}.totalPrice`}
              control={control}
              inputProps={{ style: { textAlign: "right" } }}
              defaultValue={formData.totalPrice}
              type="number"
            />
          ) : (
            getTypographyOfFormValue("totalPrice")
          )}
        </Grid>
      )}

      {companyType !== "foreign" && (
        <Grid item xs={1.5}>
          {isEditMode ? (
            <TextFieldWithReactHookForm
              disabled={
                formData.name === "WFG" ||
                formData.name === "PFS" ||
                formData.name === "보험료"
              }
              name={`withdrawalData.${formListIndex}.vatPrice`}
              control={control}
              inputProps={{ style: { textAlign: "right" } }}
              defaultValue={formData.vatPrice}
              type="number"
            />
          ) : (
            getTypographyOfFormValue("vatPrice")
          )}
        </Grid>
      )}

      <Grid item xs={2}>
        {isEditMode ? (
          <TextFieldWithReactHookForm
            name={`withdrawalData.${formListIndex}.finalPrice`}
            control={control}
            inputProps={{ style: { textAlign: "right" } }}
            defaultValue={formData.finalPrice}
            type="number"
          />
        ) : (
          getTypographyOfFormValue("finalPrice")
        )}
      </Grid>

      <Grid item container xs={1.8}>
        {!isEditMode && (
          <Grid item>
            <Button onClick={handleEditModeOpen}>수정</Button>
          </Grid>
        )}

        {isEditMode && (
          <Grid item>
            <Button onClick={() => setIsEditMode(false)}>확인</Button>
          </Grid>
        )}

        {isEditMode && (
          <Grid item>
            <Button onClick={handleEditModeCancel}>취소</Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={0.7}>
        <Button color="error" onClick={handleRemoveForm}>
          삭제
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormList;
