import { useMemo } from "react";
import { Box } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  TrelloBidDetail,
  UNIPASS_CUSTOM_STATUS_ITEM,
} from "@sellernote/_shared/src/types/forwarding/trello";

import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../../components/Table";

type CellId = keyof UNIPASS_CUSTOM_STATUS_ITEM | "masterName";

function CargoStatusTable({ trelloDetail }: { trelloDetail: TrelloBidDetail }) {
  const { data: uniPassCargoNumberList } =
    TRELLO_BID_QUERY.useGetUnipassCustomStatusList({ bidId: trelloDetail.id });

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "구분",
      },
      {
        id: "cargMtNo",
        disablePadding: false,
        label: "화물관리번호",
      },
      {
        id: "status",
        disablePadding: false,
        label: "통관현황",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!uniPassCargoNumberList) return [];

    return uniPassCargoNumberList.map((v) => {
      const row: TableBodyRow<CellId> = {
        id: v.id,
        cargMtNo: v.cargMtNo,
        status: v.status,
      };

      return row;
    });
  }, [uniPassCargoNumberList]);

  return (
    <Box sx={{ mb: 2, mt: 2 }}>
      <Table title={"통관 현황"} headCells={headCells} rows={rows} />
    </Box>
  );
}

export default CargoStatusTable;
