import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

export interface CardListData {
  lineKey: string;
  lineTitle: string;
  cards: {
    key: string;
    className?: string;
    title: JSX.Element;
    description: JSX.Element;
    forwardingManagerId: number;
    freightType: string;
    isRedBorder?: boolean;
    isBlueBorder?: boolean;
    projectStatus?: BidProjectStatus;
    handleTrelloCardClick: () => void;
  }[];
}

function TrelloCardList({ cardListData }: { cardListData: CardListData[] }) {
  const getForwardingManagerInfo = (forwardingManagerId: number) => {
    const targetInfo = ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST.find(
      (item) => item.id === forwardingManagerId
    );

    return targetInfo;
  };

  const getRedOrBlueBorderStyle = ({
    isBlueBorder,
    isRedBorder,
    forwardingManagerId,
  }: {
    isRedBorder?: boolean;
    isBlueBorder?: boolean;
    forwardingManagerId: number;
  }) => {
    if (!isBlueBorder && !isRedBorder) {
      return `1px solid ${
        getForwardingManagerInfo(forwardingManagerId)?.border
      }`;
    }

    if (isBlueBorder) {
      return "4px solid #2196f3";
    }

    return "4px solid #f5222d";
  };

  return (
    <Box sx={{ display: "flex", overflow: "auto", padding: 1 }}>
      {cardListData.map((cardList, index) => (
        <Box
          key={cardList.lineTitle}
          sx={{
            padding: 1,
            minWidth: 210,
            maxWidth: 210,
            backgroundColor: grey[200],
            borderRadius: 1,
            margin: "0 8px",
            overflowY: "auto",
            height: window.innerHeight - 150,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: grey[600], fontWeight: "bold" }}
          >
            {cardListData[index].lineTitle}
          </Typography>

          {cardList.cards.map((card, index) => {
            return (
              <Card
                onClick={() => card.handleTrelloCardClick()}
                key={card.key}
                sx={{
                  marginBottom: 1,
                  cursor: "pointer",
                  backgroundColor: getForwardingManagerInfo(
                    card.forwardingManagerId
                  )?.backgroundColor,
                  border: getRedOrBlueBorderStyle({
                    isBlueBorder: card.isBlueBorder,
                    isRedBorder: card.isRedBorder,
                    forwardingManagerId: card.forwardingManagerId,
                  }),
                }}
              >
                <CardContent sx={{ padding: "4px 4px" }}>
                  {card.title}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {card.freightType}
                    </Typography>

                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {getForwardingManagerInfo(card.forwardingManagerId)?.name}
                    </Typography>
                  </Box>

                  <Divider
                    sx={{
                      backgroundColor: getForwardingManagerInfo(
                        card.forwardingManagerId
                      )?.border,
                    }}
                  />

                  {card.description}
                </CardContent>
              </Card>
            );
          })}
        </Box>
      ))}
    </Box>
  );
}

export default TrelloCardList;
