import { useMemo } from "react";

import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import { changePurchaseDomainToKr } from "@sellernote/_shared/src/utils/forwarding/trello";

export interface PartnerListForAutoComplete {
  label: string;
  value: number | "shipper" | null;
}

export default function usePartnerNameAutoCompleteOptions({
  partnerList,
}: {
  partnerList: PartnerListItem[] | undefined;
}) {
  const customsPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return (
          v.businessArea === "customs" || v.businessArea === "shipDaCustoms"
        );
      })
      .map((n: PartnerListItem) => {
        if (n.businessArea === "shipDaCustoms") {
          return { label: n.name, value: n.id };
        } else {
          return { label: `[화주]${n.name}`, value: n.id };
        }
      });

    return partnerListForAutoComplete.concat(
      //초기값과 구별하는 화주발송 전용 value 추가
      { label: "화주 발송", value: "shipper" }
    );
  }, [partnerList]);

  const wareHousePartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === "wareHouse";
      })
      .map((n: PartnerListItem) => {
        return { label: n.name, value: n.id };
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  const shippingPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === "shipping";
      })
      .map((n: PartnerListItem) => {
        return { label: n.name, value: n.id };
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  const inlandPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === "inland";
      })
      .map((n: PartnerListItem) => {
        return { label: n.name, value: n.id };
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  const etcPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] =
      partnerList.map((n: PartnerListItem) => {
        if (n.businessArea !== "customs") {
          return {
            label: `${n.name}[${changePurchaseDomainToKr(n.businessArea)}]`,
            value: n.id,
          };
        } else {
          return {
            label: `[화주]${n.name}[${changePurchaseDomainToKr(
              n.businessArea
            )}]`,
            value: n.id,
          };
        }
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  const foreignPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === "foreign";
      })
      .map((n: PartnerListItem) => {
        return { label: n.name, value: n.id };
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  const domesticPartnerListForAutoComplete = useMemo(() => {
    if (!partnerList) {
      return [];
    }
    const partnerListForAutoComplete: PartnerListForAutoComplete[] = partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === "domestic";
      })
      .map((n: PartnerListItem) => {
        return { label: n.name, value: n.id };
      });

    return partnerListForAutoComplete.concat({
      label: "-",
      value: null,
    });
  }, [partnerList]);

  return {
    domesticPartnerListForAutoComplete,
    foreignPartnerListForAutoComplete,
    shippingPartnerListForAutoComplete,
    wareHousePartnerListForAutoComplete,
    customsPartnerListForAutoComplete,
    inlandPartnerListForAutoComplete,
    etcPartnerListForAutoComplete,
  };
}
