import { useMemo } from "react";
import { UseFormWatch } from "react-hook-form";

import { WarehouseDocumentForm } from "@sellernote/_shared/src/types/forwarding/trello";

import filledLogo from "../../../../../../../../images/createDocument/filledLogo.png";

export default function useWarehouseDocumentPreview(
  watch: UseFormWatch<WarehouseDocumentForm>,
  docNum: string
) {
  const {
    warehouseName,
    company,
    blNumber,
    packageInfo,
    weight,
    cbm,
    adminName,
    adminPhone,
    comment,
    faxNumber,
    adminEmail,
  } = watch();

  const warehouseDocumentHtml = useMemo(() => {
    return `<!DOCTYPE html>
    <html lang="ko">
      <head>
        <link
          href="https://webfontworld.github.io/sunn/SUIT.css"
          rel="stylesheet"
        />
        <meta charset="UTF-8" />
        <title>Title</title>
        <style type="text/css"></style>
      </head>
    
      <body
        style="
          font-family: SUIT;
          width: 595px;
          height: 842px;
          background-color: #ffffff;
        "
      >
        <div
          style="
            padding: 32px;
            letter-spacing: -0.001em;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
          "
        >
          <div style="text-align: right">
            <img alt="" src="${filledLogo}" style="width: 58.8px; height: 24px" />

            <div style="font-size: 12px;">Doc#${docNum}</div>
          </div>
    
          <div style="font-size: 18px; text-align: center; font-weight: 700">
            창고료 내역서 요청
          </div>
    
          <div style="margin-top: 40px; display: flex">
            <div>[수신]</div>
    
            <div style="font-weight: 500; margin-left: 16px">${warehouseName}</div>
          </div>
    
          <div style="border: 1px solid #7a7a7a; margin-top: 24px"></div>
    
          <div style="margin-top: 24px">
            <div>안녕하세요. 셀러노트입니다.</div>
          </div>
    
          <div style="border: 1px dashed #b0b0b0; margin-top: 24px"></div>
    
          <div style="margin-top: 24px; display: flex">
            <div>[화주명]</div>
    
            <div style="font-weight: 500; margin-left: 16px">${company}</div>
          </div>
    
          <div style="margin-top: 24px; display: flex">
            <div>[HBL#]</div>
    
            <div style="font-weight: 500; margin-left: 16px">${blNumber}</div>
          </div>
    
          <div style="margin-top: 24px; display: flex; font-weight: 700">
            <div>${packageInfo} / ${weight} / ${cbm}</div>
          </div>
    
          <div style="border: 1px dashed #b0b0b0; margin-top: 24px"></div>
    
          <div style="margin-top: 24px; margin-left: 8px">${comment}</div>
    
          <div style="margin-top: 40px; text-align: right">
            <div style="font-weight: 700; font-size: 18px">${adminName}</div>
    
            <div style="margin-top: 8px"><b>Fax.</b> ${faxNumber}</div>
    
            <div style="margin-top: 8px"><b>E-mail.</b> ${adminEmail}</div>
    
            <div style="margin-top: 8px"><b>Tel.</b> ${adminPhone}</div>
          </div>
        </div>
      </body>
    </html>`;
  }, [
    docNum,
    warehouseName,
    company,
    blNumber,
    packageInfo,
    weight,
    cbm,
    comment,
    adminName,
    faxNumber,
    adminEmail,
    adminPhone,
  ]);

  return warehouseDocumentHtml;
}
