import styled from "styled-components";

const basicDescContainer = styled.div`
  .descriptions {
    font-weight: bold;
  }

  .user-company {
    margin-top: -5px;
    padding-left: 0px;
  }

  .status-select {
    width: 263px;
    margin-top: -5px;
  }

  .forwarding-manager {
    cursor: pointer;
    color: #009de7;
  }
`;

export default {
  basicDescContainer,
};
