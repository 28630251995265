import { atomWithReset } from "jotai/utils";

const BID_TABLE_PAGINATION = atomWithReset({
  page: 0,
  perPage: 20,
});

export default {
  BID_TABLE_PAGINATION,
};
